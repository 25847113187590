
import { mapGetters } from "vuex";
export default {
    data() {
        return {}
    },
    computed: {
        ...mapGetters("auth", [
            "getAuthenticatedUser",
            "getActiveWorkspace",
        ]),
        ...mapGetters("paymentGateway", [ "getPaymentsHistory","getPlansFeatures"]),
    },
    methods: {
        async getPlanExpireDetails() {
            console.log("getPlansFeatures",this.getPaymentsHistory)

            // return this.getPaymentsHistory.planFeatures
            return true


            // // this.getSubscriptionHistory();
            // let diff=0;
            // let planExpireDetails={};
            // let expireAt = new Date(this.getActiveWorkspace.created_at); // Workspace Created Date for validation purpose 
            // let endDate = this.$moment(this.expireAt);
            // let startDate = this.$moment(new Date());
            // if (this.getPaymentsHistory && this.getPaymentsHistory.data && this.getPaymentsHistory.data.length) {
            //     let todayDate = this.$moment().utc();
            //     let ActiveSubscriptionInfo = this.getPaymentsHistory.data[0];
            //     let startDay = this.$moment(ActiveSubscriptionInfo.subscriptionInfo.template_data.subscription_start_date).utc().format();
            //     if (ActiveSubscriptionInfo.planDetails.template_data.bill_every_1 === 'Month') {
            //         // planPlusDays
            //         expireAt = this.$moment(startDay).add(parseInt(ActiveSubscriptionInfo.planDetails.template_data.bill_every), 'month').utc();
            //     }
            //     let diff_StartDate_today = todayDate.diff(startDay, 'days');
            //     diff = expireAt.diff(startDay, 'days');
            //     if (diff_StartDate_today < diff) {
            //         console.log("Package is Valid", expireAt.diff(diff_StartDate_today, 'days'))
            //     }
            //     else {
            //         diff = 0
            //     }

            //     planExpireDetails = {
            //         activePlan: ActiveSubscriptionInfo.planDetails.template_data.plan_name,
            //         expireDate: expireAt,
            //         diff,plan:1
            //     }

            //     // let invoicesData = this.getPaymentsHistory.data.map(async (inv) => {
            //     //     return {
            //     //         inv_date: this.$moment(inv.invoiceInfo.template_data.invoice_date).format("DD-MM-YYYY"),
            //     //         invoice_id: inv.invoiceInfo.template_data.invoice_id,
            //     //         subscription_name: inv.invoiceInfo.template_data['subscription/name'],
            //     //         customer_name: inv.invoiceInfo.template_data['customer/name'],
            //     //         plan_name: inv.invoiceInfo.template_data['plan/name'],
            //     //         amount: inv.invoiceInfo.template_data.net_amount + " " + inv.invoiceInfo.template_data.net_amount_currency

            //     //     }
            //     // })

            //     // this.invoiceData = await Promise.all(invoicesData)
                
            //     return planExpireDetails
            // }
            // else {
            //     expireAt.setDate(expireAt.getDate() + 6);
            //     diff = endDate.diff(startDate, 'days');
            //     planExpireDetails = {
            //         activePlan: 'Free Plan',
            //         expireDate: expireAt,
            //         diff,plan:0
            //     }
            //     return planExpireDetails
            // }

        },
        async getSubscriptionHistory() {
            try {
                let email = 'info@esigns.io';
                await this.$store.dispatch("paymentGateway/fetchSubscriptionInfo", { email });
                //this.getPlanExpireDetails();
            }
            catch (error) {
                this.loading = false;
                console.log(error)
            }
        },
    },
}