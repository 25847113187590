<template>
    <el-row :gutter="10" class="expired" >
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"
        >
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 464 390.4" style="enable-background:new 0 0 464 390.4;" xml:space="preserve">
   
     <circle class="st0" cx="126" cy="175.4" r="12"/>
     <circle class="st0" cx="339" cy="175.4" r="12"/>
     <circle class="st1" cx="232.5" cy="170.9" r="106.5"/>
     <path class="st2" d="M126,164.4c0,0,4.5-15.4,10.5-19.6c0,0,31,0,65-26.5c0,0,110,85.9,176-30.8c0,0-33,28.6-116-41.4
       c0,0-131-16.2-135.5,106V164.4z"/>
     <path class="st2" d="M339,164.4c0,0,6.2-13.3-8.2-32.4l-6.3,3.9C324.5,135.9,333.5,142.9,339,164.4z"/>
     <path class="st2" d="M247.8,45.3c0,0,47.7-5.3,76.7,53.7L247.8,45.3z"/>
     <circle class="st2" cx="192" cy="175.4" r="9"/>
     <circle class="st2" cx="271" cy="175.4" r="9"/>
     <path class="st4" d="M101.4,390.1c22.1-106.8,75.7-114.1,137.1-114.1c61.4,0,104,18.8,130.1,114.1
       C368.7,390.6,101.3,390.6,101.4,390.1z"/>
   
     <circle id="path" class="st3 uhoh" cx="234.5" cy="230.5" r="20"/>
     <path class="st3 smile" d="M191,214.4c-1.1-1.5,38.6,49.3,83,0"/>
     </svg>
        <div class="message" v-if="getUserType && getUserType.name=='OWNER'">
          <h1>Oops, Your subscription package has expired.</h1>

          <p >Kindly note that your subscription has lapsed. Please renew your account to continue enjoying uninterrupted access to our premium services.Renew Your Subscription Today and Unlock a World of Possibilities! </p>
          <span @click="subscription" style="cursor:pointer" >Click here to renew your package</span>
        </div>

        <div class="message"  v-else-if="getUserType && getUserType.name!='OWNER'">
          <h1>Stay Connected, Stay Subscribed!</h1>

          <p >Review Your Subscription for Uninterrupted Access! Reach Out to Your Administrator Now. </p>

          <!-- <a href="" target="_blank">Go to Roundee.io</a> -->
        </div>
        <div class="message"  v-else>
          <h1>Stay Connected, Stay Subscribed!</h1>
          <br/>
          <p >Review Your Subscription for Uninterrupted Access! Reach Out to Your Administrator Now. </p>

          <!-- <a href="" target="_blank">Go to Roundee.io</a> -->
        </div>
                  
        <br /><br /><br />
          <!-- <div class="light">
          <span></span>
          <span></span>
          <span></span>
        </div>
          <div class="light_btm">
          <span></span>
          <span></span>
          <span></span>
        </div> -->
      </el-col>
    </el-row>
    <!-- <div class="expired">        
      
      </div> -->
</template>

<script>
import { mapGetters } from "vuex";
// import DocumentsList from "@/components/companyDocuments/DocumentsList";
import PermissionsHelper from "@/mixins/permissionsHelper";
import MobileRelatedHelper from "@/mixins/MobileRelatedHelper";
import PackageHelpers from "@/mixins/packageHelper"
export default {
  name: "Expire",

  metaInfo() {
    return {
      title: "eSigns - Dashboard",
      meta: [
        {
          name: "description",
          content:
            "eSigns understands your business process better, faster, and quicker than the rest of the world to ensure hassle-free and paperless workflow management.",
        },
        {
          property: "og:title",
          content: "eSigns - Dashboard",
        },
        { property: "og:site_name", content: "eSigns" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  props: ["pageSizes", "type", "categoryId", "groupId"],
  mixins: [PermissionsHelper, MobileRelatedHelper, PackageHelpers],
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveWorkspace",
      "getUserType",
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("paymentGateway", ["getPlansData", "getActivePaymentSystems", "getPaymentGatewayInfo", "getUpdateSystemInfo", "getPayment", "getPaymentsHistory"]),
  },
  async mounted() {

   },
  methods: {
    async subscription(){
      // this.$router.push({  name: 'subscription' });
      this.$router.push({  path: '/plans-list' });
    },
    async getPlanExpireDetails() {
            this.loading=true;
            if (this.getPaymentsHistory && this.getPaymentsHistory.data && this.getPaymentsHistory.data.length) {
                this.planExpireDetails =this.getPaymentsHistory && this.getPaymentsHistory.planFeatures&&this.getPaymentsHistory.planFeatures[0]

                let invoicesData=this.getPaymentsHistory.data.map(async(inv)=>{
                    return {
                            inv_date:this.$moment(inv.invoiceInfo.template_data.invoice_date).format("DD-MM-YYYY"),
                            invoice_id:inv.invoiceInfo.template_data.invoice_id,
                            subscription_name: inv.invoiceInfo.template_data['subscription/name'],
                            customer_name:inv.invoiceInfo.template_data['customer/name'],
                            plan_name:inv.invoiceInfo.template_data['plan/name'],
                            amount: Math.round((parseFloat(inv.invoiceInfo.template_data.net_amount) + Number.EPSILON) * 100) / 100 +" "+inv.invoiceInfo.template_data.net_amount_currency

                    }
                })
                this.invoiceData= await Promise.all(invoicesData)
                this.loading=false
            }
            else if (this.getPaymentsHistory && this.getPaymentsHistory.planFeatures && this.getPaymentsHistory.planFeatures.length) {
                this.planExpireDetails =this.getPaymentsHistory && this.getPaymentsHistory.planFeatures&&this.getPaymentsHistory.planFeatures[0];
                this.loading=false
            }
            else {
                this.expireAt = new Date(this.getActiveWorkspace.created_at);
                this.expireAt.setDate(this.expireAt.getDate() + 6);
                let endDate = this.$moment(this.expireAt);
                let startDate = this.$moment(new Date());
                // this.diff = endDate.diff(startDate, 'days');
                this.planExpireDetails = {
                    activePlan: 'Free Plan',
                    expireDate: new Date(),
                    diff:endDate.diff(startDate, 'days')
                }
                this.loading=false
            }

        },
    async getSubscriptionHistory() {
            try {
                this.loading = true;
                let email = 'admin@bainfotech.in';
                await this.$store.dispatch("paymentGateway/fetchSubscriptionInfo", { email });
                this.loading = false;
                this.getPlanExpireDetails();
            }
            catch (error) {
                this.loading = false;
                console.log("getSubscriptionHistory", error)
            }
        },

    smileloop()
    {    
      // var $elem = $('.smile'), l = $elem.length, i = 0;
      // var $elem2 = $('.uhoh'), l = $elem2.length, i = 0;

      // var $elem = $('.smile');
      // var $elem2 = $('.uhoh');
      // for( var i=0; i < 3; i++ ){
      //       if(i % 2){
      //       $elem.fadeIn(700);
      //       // $elem2.fadeOut(700);
      //       }
      //       else{
      //       $elem.fadeOut(700);
      //       $elem2.hide().delay(700).fadeIn(700);
      //       }

      //     }
    }
  },
  beforeDestroy() {
  },
  
  watch: {
  getPaymentsHistory: async function(){
    if(this.getPaymentsHistory &&this.getPaymentsHistory.planFeatures && this.getPaymentsHistory.planFeatures.length ) 
      {   
      let planDetails=this.getPaymentsHistory && this.getPaymentsHistory.planFeatures&&this.getPaymentsHistory.planFeatures[0];
        if(planDetails &&  planDetails.diff)
        {
          this.$router.push("/")
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.expired{
  padding:1em;
  margin:1em;
  text-align: center;
  border-radius: 5px;
        background: rgba(96, 196, 196, .3);
        font-family: 'Open-sans', sans-serif;

      svg {
        width: 25%;
        //margin: 0 5% 3vh !important;
      }
      
      .st0{fill:#EFCBB4;}
      .st1{fill:#FFE1CA;}
      .st2{fill:#473427;}
      .st3{
          fill:none;
          stroke:#473427;
          stroke-width:7;
          stroke-linecap:round;
          stroke-miterlimit:10;
      }
      .st4{fill:#D37D42;stroke:#D37D42;stroke-miterlimit:10;}
      .smile {
        //display: none;
      }
      .uhoh {
        display: none;
      }
      path.smile {
          fill-opacity: 0;
          stroke: #000;
          stroke-width: 6;
          stroke-dasharray: 870;
          stroke-dashoffset: 870;
          animation: draw 9s infinite linear;
        }
      @keyframes draw {
        to {
          stroke-dashoffset: 0;
        }
      }
      #path {
        stroke-dasharray: 628.3185307179587;
        animation: dash 3s linear forwards;
      }
      @keyframes dash {
        from {
          stroke-dashoffset: 628.3185307179587;
        }
        to {
          stroke-dashoffset: 0;
        }
      }
      
      .message {
        //float: right;
        //margin: 10em 10em 0 0;
        //padding: 0 2em;
      }
      .message h1 {
        color: #3698DC;
        font-size: 1.5vw !important;
        font-weight: 400;
      }
      .message p {
        color: #262C34;
        font-size: 1em;
        font-weight: lighter;
        line-height: 1.1em;
      }
      .light {
        position: relative;
        top: -36em;
      }
      .light_btm {
        position: relative;
      }
      .light span:first-child {
        display: block;
        height: 6px;
        width: 150px;
        position: absolute;
        right:1em;
        bottom: 5em;
        background: #fff;
        border-radius: 3px;
      /*   transform: rotate(40deg); */
      }
      .light span:nth-child(2) {
        display: block;
        height: 6px;
        width: 200px;
        position: absolute;
        top:6em;
        left: 19em;
        background: #fff;
        border-radius: 3px;
      /*   transform: rotate(40deg); */
      }
      .light span:nth-child(3) {
        display: block;
        height: 6px;
        width: 100px;
        position: absolute;
        top:7em;
        left: 24em;
        background: #fff;
        border-radius: 3px;
      /*   transform: rotate(40deg); */
      }
      
      .light_btm span:first-child {
        display: block;
        height: 6px;
        width: 150px;
        position: absolute;
        bottom:6em;
        right: 20em;
        background: #fff;
        border-radius: 3px;
      /*   transform: rotate(40deg); */
      }
      .light_btm span:nth-child(2) {
        display: block;
        height: 6px;
        width: 200px;
        position: absolute;
        bottom: 7em;
        right: 21em;
        background: #fff;
        border-radius: 3px;
      /*   transform: rotate(40deg); */
      }
      .light_btm span:nth-child(3) {
        display: block;
        height: 6px;
        width: 100px;
        position: absolute;
        bottom:8em;
        right: 24em;
        background: #fff;
        border-radius: 3px;
      /*   transform: rotate(40deg); */
      }
      .use-desktop {
        font-weight: 400;
        color: #3698DC;
        border: 1px solid white;
        height: 3.4em;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        border-radius: 25px;
        line-height: 1.1em;
        font-size: 5vw;
      }
      
}
</style>
